import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import EnterLostDisc from "./components/EnterLostDisc";
import Inventory from "./components/Inventory";
import "./globals.css";
import { Box, Button, ButtonGroup, Typography } from "@mui/material"; // Import Button and ButtonGroup from MUI
import AdminPanel from "./components/AdminPanel";

// Define a Disc interface
export interface Disc {
  id?: number;
  course: string;
  name: string;
  disc: string;
  phoneNumber: string | null;
  bin: string | null;
  dateFound: string;
  dateTexted?: string | null;
  dateClaimed?: string | null;
  status: DiscStateString;
  comments?: string | null;
  color: string;
  claimBy?: string | null;
  brand?: string | null;
  dateSold?: string | null;
}
export enum DiscStateString {
  Unclaimed = "UNCLAIMED",
  PendingDropoff = "PENDING_DROPOFF",
  PendingStorePickup = "PENDING_STORE_PICKUP",
  PendingCoursePickup = "PENDING_COURSE_PICKUP",
  Claimed = "CLAIMED",
  PickupOverdue = "PICKUP_OVERDUE",
  ForSale = "FOR_SALE",
  Sold = "SOLD",
  SoldOffline = "SOLD_OFFLINE",
  Surrendered = "SURRENDERED",
}

export const API_BASE_URL = "https://api.discrescuenetwork.com"; //production URL
// export const API_BASE_URL = "http://127.0.0.1:3001"; // local testing
//export const API_BASE_URL = "http://127.0.0.1:5000"; // local testing option 2

function App() {
  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Routes>
        <Route path="/" element={<AdminPanel />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <p className="copywrite">Copyright 2024 Disc Rescue Network LLC</p>
    </Box>
  );
}

export default App;
